import React from 'react';

function Redirect({content}) {
  window.location.replace(content.url);

  return (
    <div>Redirecting...</div>
  );
}

export default Redirect;
