import React, {useEffect, useState} from 'react';
import {withFirebase} from '../Firebase';
import {doc, getDoc} from 'firebase/firestore';
import contentMapper from './contentMapper';
import './style.css';

function Content({firebase}) {
  const db = firebase.getFirestore();
  const [content, setContent] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getContent = async () => {
      const settingsDocRef = doc(db, 'settings/settings');
      const settings = await getDoc(settingsDocRef);
      const id = settings.data().currentContent;

      const contentDocRef = doc(db, `content/${id}`);
      const content = await getDoc(contentDocRef);

      setContent(content.data());
      setLoading(false);
    };

    getContent();
  }, [db]);


  const ContentComponent = contentMapper[content.type]?.content;
  return !loading
    ? <div className="content-container"><ContentComponent content={content}/></div>
    : <div className="content-loading">Loading...</div>;
}

export default withFirebase(Content);
