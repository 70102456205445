import React from 'react';

function Logo({className}) {
  return (
    <span className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
        <g id="Rectangle_5" data-name="Rectangle 5" fill="#131313" stroke="#fff" strokeWidth="5">
          <rect width="100" height="100" stroke="none"/>
          <rect x="5" y="5" width="90" height="90" fill="none"/>
        </g>
        <rect id="Rectangle_6" data-name="Rectangle 6" width="20" height="20" transform="translate(40 40)" fill="#fff"/>
      </svg>
    </span>
  );
}

export default Logo;
