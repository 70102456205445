import React, {useEffect, useState} from 'react';
import {withFirebase} from '../Firebase';
import {collection, deleteDoc, doc, getDoc, getDocs, updateDoc, query, orderBy} from 'firebase/firestore';
import './style.css';
import contentMapper from '../Content/contentMapper';
import ContentPreview from './ContentPreview';
import {Link} from 'react-router-dom';
import Logo from '../SVG/Logo';
import Cookies from 'universal-cookie';

function Admin({firebase}) {
  const db = firebase.getFirestore();
  const [content, setContent] = useState([]);
  const [current, setCurrent] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [typeFilter, setTypeFilter] = useState('');
  const [nameFilter, setNameFilter] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  if (!loggedIn && cookies.get('logged-in')) {
    setLoggedIn(true);
    setLoading(true)
  }

  const getFilters = () => {
    return [
      {field: 'type', value: typeFilter},
      {field: 'name', value: nameFilter},
    ];
  };

  useEffect(() => {
    const getContent = async () => {
      const settingsDocRef = doc(db, 'settings/settings');
      const settings = await getDoc(settingsDocRef);
      const currentId = settings.data().currentContent;

      const contentArray = [];
      const q = query(collection(db, 'content'), orderBy('created', 'desc'));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        contentArray.push({
          id: doc.id,
          ...doc.data(),
        });

        if (doc.id === currentId) {
          setCurrent({
            id: doc.id,
            ...doc.data(),
          });
        }
      });

      setContent(contentArray);
      setTimeout(() => setLoading(false), 3000);
    };

    getContent();
  }, [db, refresh]);

  const updateCurrentContent = async id => {
    const settingsRef = doc(db, 'settings/settings');

    await updateDoc(settingsRef, {
      currentContent: id,
    });

    setCurrent(content.find(c => c.id === id));
  };

  const deleteContent = async id => {
    await deleteDoc(doc(db, 'content', id));
    setContent([]);
    setRefresh(Date.now());
  };

  const passesFilter = c => {
    if (c.id === current.id) {
      return false;
    }

    for (const {field, value} of getFilters()) {
      if (value) {
        const formattedFilterValue = value.toLowerCase();
        const formattedContentValue = c[field] ? c[field].toLowerCase() : '';

        if (!formattedContentValue.includes(formattedFilterValue)) {
          return false;
        }
      }
    }

    return true;
  };

  const renderContent = content.map((c) => {
    if (passesFilter(c) && c.id !== current.id) {
      const preview = contentMapper[c.type]?.preview;
      return <ContentPreview id={c.id}
                             key={c.id}
                             name={c.name}
                             type={c.type}
                             text={c[preview?.text]}
                             imageUrl={c[preview?.image]}
                             update={updateCurrentContent}
                             deleteContent={deleteContent}
      />;
    }
    return null;
  });

  const currentPreview = contentMapper[current.type]?.preview;
  const currentContent = <ContentPreview id={current.id}
                                         key={current.id}
                                         name={current.name}
                                         type={current.type}
                                         text={current[currentPreview?.text]}
                                         imageUrl={current[currentPreview?.image]}
                                         update={updateCurrentContent}
                                         deleteContent={deleteContent}
                                         current={true}
  />;

  const options = Object.keys(contentMapper).map(type => (
    contentMapper[type].fields
      ? <option value={type} key={type}>{type.toUpperCase()}</option>
      : null
  ));

  const login = password => {
    if (password === 'poeldijk') {
      setLoggedIn(true);
      cookies.set('logged-in', true, { path: '/admin', maxAge: 3600 * 24 * 365 });
    }
  };

  return loggedIn ? (
    <>
      {loading ?
        <div className="admin-loading">
          <Logo className="admin-loading--logo"/>
          <div className="admin-loading--title">Qrapp</div>
        </div>
        :
        <div className="admin-page">
          <div className="header">
            <div className="header-title">
              <Logo className="header-logo"/> Qrapp.
            </div>
            <Link to="/admin/new" className="create-content-button">+</Link>
          </div>
          {currentContent}
          <div className="filter">
            <select
              name="type"
              id="filter-type"
              className="filter-select"
              onChange={e => setTypeFilter(e.target.value)}
            >
              <option key="all-types" value="">Type</option>
              {options}
            </select>
            <input
              type="text"
              name="name"
              id="filter-name"
              className="filter-text"
              placeholder="Search..."
              onChange={e => setNameFilter(e.target.value)}
            />
          </div>
          <div>
            {renderContent}
          </div>
        </div>
      }
    </>
  ) : (
    <div className="admin-page">
      <input
        type="password"
        name="password"
        className="filter-text"
        placeholder="Password"
        onChange={e => login(e.target.value)}
      />
    </div>
  );
}

export default withFirebase(Admin);
