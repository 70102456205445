import React, {useState} from 'react';
import {withFirebase} from '../../Firebase';
import {addDoc, collection, doc, updateDoc, Timestamp} from 'firebase/firestore';
import contentMapper from '../../Content/contentMapper';
import './style.css';
import Logo from '../../SVG/Logo';

function Create({firebase}) {
  const db = firebase.getFirestore();
  const [type, setType] = useState(null);
  const [formData, setFormData] = useState({type, created: Timestamp.fromDate(new Date()) });
  const [current, setCurrent] = useState(false);

  const options = Object.keys(contentMapper).map(type => (
    contentMapper[type]
      ? <option value={type} key={type}>{type}</option>
      : null
  ));

  const getFieldsOfType = type => {
    const FieldsComponent = contentMapper[type].fields;
    return FieldsComponent
      ? <FieldsComponent formData={formData} setFormData={setFormData}/>
      : null;
  };

  const submit = async e => {
    e.preventDefault();
    const docRef = await addDoc(collection(db, 'content'), formData);

    if (docRef.id) {
      if (current) {
        const settingsRef = doc(db, 'settings/settings');

        await updateDoc(settingsRef, {
          currentContent: docRef.id,
        });
      }

      window.location.replace('/admin');
    }
  };

  return (
    <div className="admin-page">
      <div className="header">
        <div className="header-title">
          <Logo className="header-logo"/> New.
        </div>
      </div>
      <div className="admin-create-page pulse">
        <form onSubmit={submit}>
          <div className="fieldset">
            <select name="type"
                    id="type"
                    className="filter-select create-select"
                    required
                    defaultValue=""
                    onChange={e => {
                      setType(e.target.value);
                      setFormData({...formData, type: e.target.value});
                    }}
            >
              <option value="" disabled>Choose a content type</option>
              {options}
            </select>
          </div>
          <div className="fieldset">
            <label htmlFor="name">Name</label>
            <input type="text"
                   name="name"
                   id="name"
                   className="filter-text"
                   required
                   onChange={e => {
                     setFormData({...formData, name: e.target.value});
                   }}
            />
          </div>
          {type && getFieldsOfType(type)}
          <div className="fieldset fieldset-checkbox">
            <div>
              <input type="checkbox"
                     name="set-current"
                     id="set-current"
                     className="inline-block"
                     onChange={() => {setCurrent(!current)}}
              />
              <label htmlFor="set-current" className="inline-block">Set as current content</label>
            </div>
          </div>
          <input type="submit" value="Create" disabled={!type}/>
        </form>
      </div>
    </div>
  );
}

export default withFirebase(Create);
