import React from 'react';

function RedirectFields({formData, setFormData}) {
  return (
    <div>
      <label htmlFor="url">Redirect URL</label>
      <input type="text"
             name="url"
             id="url"
             required
             onChange={e => {
               setFormData({...formData, url: e.target.value});
             }}
      />
    </div>
  );
}

export default RedirectFields;
