import React from 'react';
import {getDownloadURL, ref, uploadBytesResumable} from 'firebase/storage';
import './style.css';
import {withFirebase} from '../../../Firebase';

function ImageFields({formData, setFormData, firebase}) {
  const storage = firebase.getStorage();
  const [uploading, setUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [error, setError] = React.useState(null);

  const upload = async file => {
    setUploading(true);
    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(progress);
      },
      (error) => {
        setError(error.message);
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setFormData({...formData, src: downloadURL});
          setUploading(false);
        });
      }
    );
  };

  return (
    <div className="fieldset">
      <label htmlFor="image" className="custom-file-upload">Upload image</label>
      <input type="file"
             name="src"
             id="image"
             accept="image/*"
             onChange={e => upload(e.target.files[0])}
      />
      <p>
        {uploading && <span>Uploading...&nbsp;</span>}
        {uploadProgress > 0 && uploadProgress < 100 && <span>{uploadProgress}%</span>}
      </p>
      {uploadProgress === 100 && <p>Upload complete!</p>}
      {error && <p>{error}</p>}
    </div>
  );
}

export default withFirebase(ImageFields);
