import React from 'react';

function TextFields({formData, setFormData}) {
  return (
    <div>
      <label htmlFor="text">Text</label>
      <textarea name="text"
                id="text"
                onChange={e => {
                  setFormData({...formData, text: e.target.value});
                }}
                required={true}
                maxLength={200}
      />
    </div>
  );
}

export default TextFields;
