import React from 'react';
import './style.css';
import ReactPlayer from 'react-player';

function VideoWrapper({children}) {
  return (
    <div className="content-video--container">
      {children}
    </div>
  )
}

function Video({content}) {
  return (
    <div>
      <ReactPlayer
        url={content.src}
        width="100%"
        playing={true}
        volume={100}
        wrapper={VideoWrapper}
      />
    </div>
  );
}

export default Video;
