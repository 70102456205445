import React from 'react';
import {BsFacebook, BsInstagram, BsLinkedin, BsReddit, BsSnapchat} from 'react-icons/bs';
import {GrSoundcloud} from 'react-icons/gr';
import './style.css';

function Socials() {
  return (
    <div className="content-socials--container">
      <div className="content-socials--container-flex">
        <div>
          <a href="https://www.instagram.com/perry0174" target="_blank" rel="noreferrer">
            <BsInstagram/>
            <div>perry0174</div>
          </a>
        </div>
        <div>
          <a href="https://www.snapchat.com/add/peerjanssen" target="_blank" rel="noreferrer">
            <BsSnapchat/>
            <div>peerjanssen</div>
          </a>
        </div>
        <div>
          <a href="https://soundcloud.com/silicondreams_exe" target="_blank" rel="noreferrer">
            <GrSoundcloud/>
            <div>silicon dreams</div>
          </a>
        </div>
        <div>
          <a href="https://www.reddit.com/user/phperry" target="_blank" rel="noreferrer">
            <BsReddit/>
            <div>phperry</div>
          </a>
        </div>
        <div>
          <a href="https://www.facebook.com/perry.janssen.98" target="_blank" rel="noreferrer">
            <BsFacebook/>
            <div>perry.janssen.98</div>
          </a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/perry-janssen-746928142" target="_blank" rel="noreferrer">
            <BsLinkedin/>
            <div>Perry Janssen</div>
          </a>
        </div>
      </div>

    </div>
  );
}

export default Socials;
