import React from 'react';
import './style.css';

function Text({content}) {
  return (
    <div className="content-text--container">
      <div className="content-text--text">{content.text}</div>
    </div>
  );
}

export default Text;
