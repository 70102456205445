import React from 'react';
import './style.css';

function Image({content}) {
  return (
    <div className="content-image--container">
      <img
        src={content.src}
        alt=""
        className="content-image--image"
      />
      <p className="content-image--text">{content.name}</p>
    </div>
  );
}

export default Image;
