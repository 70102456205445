import {initializeApp} from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import {getStorage} from 'firebase/storage';

const config = {
  apiKey: "AIzaSyCPSqCzzkJXzVazd-_F5U_q4ZYERQIMJg0",
  authDomain: "perryjanssen-qr.firebaseapp.com",
  projectId: "perryjanssen-qr",
  storageBucket: "perryjanssen-qr.appspot.com",
  messagingSenderId: "527062170380",
  appId: "1:527062170380:web:8c30361093c77f36c32729",
};

class Firebase {

  constructor() {
    this.app = initializeApp(config);
  }

  getFirestore = () => getFirestore(this.app);
  getStorage = () => getStorage(this.app);
}

export default Firebase;
