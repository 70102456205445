import React from 'react';
import './style.css';

function VideoFields({formData, setFormData}) {
  return (
    <div>
      <label htmlFor="src">Video full URL</label>
      <input type="text"
             name="src"
             id="src"
             onChange={e => {
               setFormData({...formData, src: e.target.value});
             }}
      />
    </div>
  );
}

export default VideoFields;
