import React from 'react';
import './style.css';
import {Link} from 'react-router-dom';

function ContentPreview({
                          name,
                          type,
                          id,
                          text = null,
                          imageUrl = null,
                          update = null,
                          deleteContent = null,
                          current = false,
                        }) {
  const className = 'preview-item' + (current ? ' pulse' : '');
  const truncate = text => text.length > 50 ? `${text.substring(0, 50)}...` : text;

  return (
    <div className={className} key={id}>
      <div className="preview-item--content">
        <div className="preview-item--content-left">
          <h3 className="preview-item--title">{name}</h3>
          <p className="preview-item--type">{type}</p>
          {text &&
            <p className="preview-item--text">{truncate(text)}</p>
          }
        </div>
        <div className="preview-item--content-right">
          {imageUrl &&
            <img
              src={imageUrl}
              alt={`Preview of ${name}`}
              className="preview-item--image"
            />
          }
        </div>
      </div>
      <div className="preview-item--actions">
        {current
          ? <Link to="/" target="_blank"><button className="preview-item--button preview-item--link">View</button></Link>
          : <div className="preview-item--buttons">
            <button onClick={() => update(id)} className="preview-item--button">Set as current</button>
            <button onClick={() => deleteContent(id)} className="preview-item--button preview-item--buttons--delete">Delete</button>
          </div>
        }
      </div>
    </div>
  );
}

export default ContentPreview;
