import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import Firebase, { FirebaseContext } from './Components/Firebase';
import Content from './Components/Content';
import Admin from './Components/Admin';
import {Routes} from 'react-router';
import Create from './Components/Admin/Create';
import Redirect from './Components/Content/ContentTypes/Redirect';

function App() {
  return (
    <div className="App">
      <FirebaseContext.Provider value={new Firebase()}>
        <Router>
          <Routes>
            <Route exact path="/" element={<Content/>} />
            <Route exact path="/admin" element={<Admin/>} />
            <Route exact path="/admin/new" element={<Create/>} />
            <Route exact path="/tgchat" element={<Redirect content={{url: "https://t.me/sdaynoam"}}/>} />
          </Routes>
        </Router>
      </FirebaseContext.Provider>
    </div>
  );
}

export default App;
