import Image from './ContentTypes/Image';
import Redirect from './ContentTypes/Redirect';
import ImageFields from './ContentTypes/Image/fields';
import RedirectFields from './ContentTypes/Redirect/fields';
import Socials from './ContentTypes/Socials';
import Text from './ContentTypes/Text';
import TextFields from './ContentTypes/Text/fields';
import Video from './ContentTypes/Video';
import VideoFields from './ContentTypes/Video/fields';

const contentMapper = {
  text: {
    content: Text,
    fields: TextFields,
    preview: {text: 'text'},
  },
  image: {
    content: Image,
    fields: ImageFields,
    preview: {image: 'src'},
  },
  video: {
    content: Video,
    fields: VideoFields,
  },
  redirect: {
    content: Redirect,
    fields: RedirectFields,
    preview: {},
  },
  socials: {
    content: Socials,
    preview: {},
  },
};

export default contentMapper;
